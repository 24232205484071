<template>
  <v-row justify="center" class="primary-text">
    <v-col cols="10">
      <v-card class="mb-5">
        <v-container>
          <v-form class="pl-5 pr-5" v-on:submit.prevent="submitForm()">
            <div class="headline pb-1">Features Selected</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  :disabled="!isRecording"
                  v-model="localConfig.introConf.isRecording"
                  label="Enable Session Recording?"
                ></v-checkbox>
                <v-checkbox
                  :disabled="!enablePoolFeature"
                  v-model="localConfig.introConf.enablePool"
                  label="Enable Questionnaire Pool?"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  v-model="localConfig.introConf.toogleCamera"
                  label="Enable camera toggle? (Experimental)"
                ></v-checkbox>
                <v-checkbox
                  v-model="localConfig.introConf.enableAgentToToggleUserCamera"
                  label="Enable Agent to Toggle End User's Camera? (Experimental)"
                ></v-checkbox> 
                <v-checkbox
                  v-model="localConfig.introConf.blackScreenCheck"
                  label="Enhanced Video Screen Alert (Experimental)"
                ></v-checkbox>
                <!-- <v-checkbox
                  v-model="localConfig.introConf.useStunTurn"
                  label="Use STUN and TURN"
                ></v-checkbox> -->
                <!-- <v-checkbox
                  class="mt-0 mb-0"
                  :disabled="!isScreenshare"
                  v-model="localConfig.introConf.isScreenshare"
                  label="Do you want Screen sharing?"
                ></v-checkbox>-->
              </v-col>
            </v-row>
            <div v-if="isVideoKYC">
              <div class="headline pb-1">Basic Configurations</div>
              <v-divider class="ml-5 mb-3"></v-divider>
              <v-row justify="center">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <!-- <v-text-field label="Ad Video URL" v-model="localConfig.introConf.advideo"></v-text-field> -->

                  <v-checkbox
                    class="mt-0 mb-0"
                    v-model="localConfig.introConf.isThankYouRequired"
                    label="Do you want to show thankyou page to user?"
                  ></v-checkbox>
                  <v-checkbox
                    v-if="localConfig.introConf.isThankYouRequired && showScheduling"
                    class="mt-0 mb-0"
                    v-model="localConfig.introConf.disableThankYouPageOnReschedule"
                    label="Do you want to disable thankyou page for Rescheduled Calls?"
                  ></v-checkbox>
                  <v-checkbox
                    class="mt-0 mb-0"
                    v-model="localConfig.introConf.consentRequired"
                    label="Do you want to show declaration?"
                  ></v-checkbox>
                  <v-text-field
                    v-if="localConfig.introConf.consentRequired"
                    label="Consent Text"
                    v-model="localConfig.introConf.consentText"
                    :rules="[...rules.textCheck, ...rules.specialCharsCheck]"
                    ref="variable_ConsentText"
                  ></v-text-field>

                  <v-text-field
                    label="Call Connect Header"
                    v-model="localConfig.introConf.officialAssignedMessage"
                    :rules="rules.alphanumCheck"
                    ref="variable_officialAssignedMessage"
                  ></v-text-field>
                  <v-text-field
                    :rules="[...rules.onlySpaceCheck, ...rules.character20Length]"
                    ref="variable_videoCallDetailsHeader"
                    label="What should be the header for the applicant’s data in RE dashboard?"
                    v-model="videoCallDetailsHeader">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-checkbox
                    class="mt-0 mb-0"
                    v-model="localConfig.introConf.isThankYouMailRequired"
                    label="Do you want to send a thank you email?"
                  ></v-checkbox>
                  <v-checkbox
                    class="mt-0 mb-0"
                    v-model="localConfig.introConf.allowUserToTurnOffCamera"
                    label="Do you want to allow users to turn off camera?"
                  ></v-checkbox>
                  <v-checkbox
                    class="mt-4 mb-0"
                    v-model="localConfig.introConf.salutationTextRequired"
                    label="Do you want to change salutation text?"
                  ></v-checkbox>
                  <v-text-field
                    v-if="localConfig.introConf.salutationTextRequired"
                    label="Instruction Page Salutation Text"
                    v-model="localConfig.introConf.salutationText"
                    :rules="rules.characterLongText"
                    ref="variable_salutationText"
                  ></v-text-field>
                  <v-checkbox
                    class="mt-4 mb-0"
                    v-model="localConfig.introConf.waitingMessageRequired"
                    label="Do you want to add Waiting text?"
                  ></v-checkbox>
                  <v-text-field
                   v-if="localConfig.introConf.waitingMessageRequired"
                   label="Waiting Message from Bank"
                   v-model="localConfig.introConf.waitingMessage"
                   :rules="[...rules.characterLongText, ...rules.onlySpaceCheck]"
                   ref="variable_waitingMessage"
                 ></v-text-field>
                  <v-text-field
                    label="Reschedule Consent"
                    :rules="[...rules.textCheck, ...rules.specialCharsCheck]"
                    ref="variable_RescheduleConsentText"
                    v-model="localConfig.introConf.rescheduleConsent"
                  ></v-text-field>
                  <v-text-field
                    label="What should be the Instruction header?"
                    v-model="localConfig.introConf.instructionHeader"
                    :rules="[...rules.specialCharsCheck, ...rules.onlySpaceCheck]"
                    ref="variable_InstructionHeader"
                  ></v-text-field>

                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field
                    v-model="localConfig.introConf.expiry"
                    :rules="rules.timeCheckHours"
                    label="Call expiry in hours"
                    ref="variable_CallExpiry"
                  ></v-text-field>
                  <!-- <v-checkbox
                    class="mt-0 mb-0"
                    v-model="localConfig.introConf.keepREOnline"
                    label="Do you want agents to automatically go online after the call?"
                  ></v-checkbox> -->
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                   <v-text-field
                    label="Automatic hangup time in seconds"
                    v-model="localConfig.introConf.adminCallTimeoutDuration"
                    :rules="rules.timeCheckSeconds"
                    ref="variable_AutoHangUpTime"
                  ></v-text-field>
                </v-col>
              </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-checkbox
                      v-model="localConfig.introConf.showETA"
                      label="Do you want to show average ETA to user?"
                    ></v-checkbox>
                    <v-text-field
                      v-if="localConfig.introConf.showETA"
                      label="Average ETA for an user in minutes"
                      v-model="localConfig.introConf.averageETA"
                      :rules="rules.timeCheck"
                      ref="variable_ETA"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      label="Queue Polling Interval in seconds"
                      v-model="localConfig.introConf.pollingInterval"
                      :rules="rules.pollingCheck"
                      ref="variable_pollingInterval"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!--settings for password and login-->
                <!-- <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-checkbox
                      v-model="localConfig.introConf.isChangePasswordRequired"
                      label="Do you want to set changing login password request periodically?"
                    ></v-checkbox>
                    <v-text-field
                      v-if="localConfig.introConf.isChangePasswordRequired"
                      label="Number of days after password reset should be requested"
                      v-model="localConfig.introConf.changePasswordDuration"
                      :rules="rules.loginPasswordFieldChecks"
                      ref="password_reset_days"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-checkbox
                      v-model="localConfig.introConf.isloginAttemptLimited"
                      label="Do you want to limit number of login attempts?"
                    ></v-checkbox>
                    <v-text-field
                      v-if="localConfig.introConf.isloginAttemptLimited"
                      label="Number of login attempts for successful login"
                      :rules="rules.loginPasswordFieldChecks"
                      v-model="localConfig.introConf.allowedLoginAttempts"
                      ref="login_attemps_ref"
                    ></v-text-field>
                  </v-col>
                </v-row> -->
                <!--settings for password and login-->
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-checkbox
                      v-model="localConfig.introConf.isDocumentExpiryValidate"
                      label="Do you want to enable document expiry validation?"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      label="Minimum internet speed (in kbps) required for end user? (200kbps by default)"
                      v-model="localConfig.introConf.minSpeedRequired"
                      :rules="rules.minSpeed"
                      ref="variable_MinSpeedRequired"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!--dynamic form headers-->
                  <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      label="Admin feedback form header text"
                      v-model="localConfig.introConf.adminFeedbackFormHeaderText"
                      ref="adminform_headerText" :rules="[...rules.specialCharsCheck, ...rules.onlySpaceCheck]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      label="Admin feedback form sub header text"
                      v-model="localConfig.introConf.adminFeedbackFormSubHeaderText"
                      ref="adminform_subheaderText" :rules="[...rules.specialCharsCheck, ...rules.onlySpaceCheck]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-checkbox
                      v-model="localConfig.introConf.autoCall"
                      label="Do you want to pick the call automatic?"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-checkbox
                      v-model="localConfig.introConf.autoKyc"
                      label="Do you want to begin the kyc automatic?"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-checkbox
                      v-model="localConfig.introConf.imageCaptureThroughSocket"
                      label="Do you want image capturing through  socket (Experimental) ?"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <!--dynamic form headers-->
              <div class="headline pb-1">Language Options</div>
              <v-divider class="ml-5 mb-3"></v-divider>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                 <v-checkbox
                    v-model="localConfig.introConf.disableRuntimeTranslate"
                    label="Do you want to enable runtime translate?"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-select
                    v-model="localConfig.languagesOptions"
                    :items="languages"
                    label="Select"
                    multiple
                    chips
                    hint="What are the target languages"
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-row>
              <div class="headline pb-1 mt-5">Location Configurations</div>
              <v-divider class="ml-5 mb-3"></v-divider>
              <v-row >
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-checkbox
                    v-model="localConfig.introConf.countryWhitelisting"
                    label="Do you want to have country whitelisting?"
                  ></v-checkbox>
                  <v-autocomplete
                    v-if="localConfig.introConf.countryWhitelisting"
                    v-model="localConfig.introConf.allowedCountries"
                    :items="countryList"
                    ref="variable_AllowedCountries"
                    :rules="rules.checkEmpty"
                    item-text="Country"
                    item-value="code"
                    chips
                    label="Select the countries to be whitelisted"
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-checkbox
                    v-model="localConfig.introConf.disableVPNCheck"
                    label="Do you want to disable VPN Check?"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="localConfig.introConf.enableIpInfo"
                    label="Do you want to enable IP Information Fetching?"
                    @change="handleEnableIpInfoChange"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-checkbox
                  v-model="localConfig.introConf.locationConsistencyVerification"
                  label="Do you want Location Consistency Verification?"
                  ></v-checkbox>
                  <span v-if="localConfig.introConf.locationConsistencyVerification">
                    Note: This setting checks the location consistency between the IP address and other data sources as well. The threshold will only apply if IP infomation fetching is enabled.
                  </span>
                  <div v-if="localConfig.introConf.locationConsistencyVerification">
                    <!-- 1) Permanent address to IP address (only if enableIpInfo is also true) -->
                    <v-text-field
                      v-if="localConfig.introConf.enableIpInfo"
                      v-model="localConfig.introConf.thresholdPermanentToIp"
                      type="number"
                      label="Threshold for permanent address to IP address(in km)"
                      :rules=rules.thresholdValidationRules
                      ref="variable_thresholdPermanentToIp"
                    ></v-text-field>
                    <!-- 2) Permanent address to browser address -->
                    <v-text-field
                      v-model="localConfig.introConf.thresholdPermanentToBrowser"
                      type="number"
                      label="Threshold for permanent address to browser address(in km)"
                      :rules=rules.thresholdValidationRules
                      ref="variable_thresholdPermanentToBrowser"
                    ></v-text-field>
                    
                    <!-- 3) IP location to browser location -->
                    <v-text-field
                      v-if="localConfig.introConf.enableIpInfo"
                      v-model="localConfig.introConf.thresholdIpToBrowser"
                      type="number"
                      label="Threshold for for ip address to browser address(in km)"
                      :rules=rules.thresholdValidationRules
                      ref="variable_thresholdIpToBrowser"
                      ></v-text-field>
                  </div>
                </v-col>
             </v-row>
              <div class="headline pb-1 mt-5">RiskZone Alert Configurations</div>
              <v-divider class="ml-5 mb-3"></v-divider>
                <div class="samplecsv">
                  <p class="csvtemplate">
                    Download a 
                    <a href="#" @click.prevent="downloadSampleCSV('pincode')">sample CSV template</a> 
                    to see an example of the format required.
                  </p>   
                </div>   
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-file-input
                    ref="csvFileUpload"
                    id="csvFileUpload"
                    @click:clear="clearCSV()"
                    show-size
                    @change="uploadCSV('pincode', $event)"
                    :rules="rules.csvFileUpload"
                    hint="Only CSV files with size < 100KB"
                    :persistent-hint="true"
                    accept=".csv"
                    label="Upload New CSV File"
                  ></v-file-input>
                  <p class="filemessage" 
                    v-if="localConfig.introConf.pincodeFileName">
                    Last uploaded file: <span style="font-weight: bold; color: black;">{{ localConfig.introConf.pincodeFileName }}</span>
                  </p>
                </v-col>
              <!-- <div class="headline pb-1 mt-5">Video Encoder localConfigurations</div>
              <v-divider class="ml-5 mb-3"></v-divider>
              <v-row justify="center">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-select
                    v-model="localConfig.introConf.compressionFactor"
                    label="Select the compression quality"
                    :items="compressionMap"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                   <v-select
                    v-model="localConfig.introConf.videoQuality"
                    label="Select the Video Stream quality"
                    :items="videoQualityMap"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>
              </v-row> -->
              <!-- <div class="headline pb-1 mt-5">Backops/End User localConfigurations</div>
              <v-divider class="ml-5 mb-3"></v-divider> -->

              <div class="headline pb-1 mt-5">Scheduling Configurations</div>
              <v-divider class="ml-5 mb-3"></v-divider>
              <v-checkbox
                      v-model="localConfig.introConf.showScheduling"
                      label="Do you want customer to reschedule the video kyc calls?"
                      @change="updateShowSchedule"
                    ></v-checkbox>
              <v-row v-if="showScheduling">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-checkbox
                    v-model="localConfig.introConf.isScheduleInformation"
                    label="Send email containing scheduling information?"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="localConfig.introConf.isScheduleEmailReminder"
                    label="Send reminder email for scheduled call?"
                  ></v-checkbox>
                  <br/>
                  
                  <p>Choose the working days for scheduling window</p>
                  <v-card
                    elevation="2"
                    outlined
                    rounded
                    class="ml-5 mr-5"
                    max-width="550"
                  >
                    <v-card-title primary-title class="justify-center">
                      <v-chip-group
                        v-model="localConfig.introConf.allowedSchedulingDays"
                        multiple
                        column
                        ref="variable_SchedulingDays"
                        active-class="blue darken-2 white--text text--accent-4"
                      >
                        <v-chip
                          label
                          ripple
                          v-for="day in weekDays"
                          :key="day"
                          filter
                          filter-icon="fas fa-check"
                        >
                          {{ day }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-title>
                  </v-card>
                  <br/>
                 
                <p v-if="noWeekDaysSelected" style="color: #ff5252; margin-left: 20px; margin-top:2px; font-size: 16px;">Atleast 1 working day should be selected for rescheduling</p>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-checkbox
                    v-model="localConfig.introConf.enablePriorityScheduling"
                    label="Do you want to enable priority scheduling?"
                    class="mt-n1"
                  ></v-checkbox>
                  <v-radio-group
                    v-if="localConfig.introConf.enablePriorityScheduling"
                    v-model="localConfig.introConf.priorityQueuingType"
                    class="mt-n2 ml-3"
                    row
                  >
                    <v-radio
                      v-for="queue in queuingTypes"
                      :key="queue.label"
                      :label="queue.label"
                      :value="queue.value"
                    />
                  </v-radio-group>
                  <v-row>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                      <v-text-field
                        label="Starts At (HH:MM)"
                        v-model="localConfig.introConf.startsAt"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                      <v-text-field
                        label="Ends At (HH:MM)"
                        v-model="localConfig.introConf.endsAt"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-text-field
                    label="Please give the scheduling expiry in hours (integers)"
                    v-model="localConfig.introConf.scheduledExpiry"
                    :rules="rules.checkDays"
                    ref="variable_ScheduledExpiry"
                  ></v-text-field>
                  <v-text-field
                    label="Please give the scheduling window in days (Max limit upto 60 days)"
                    v-model="localConfig.introConf.rescheduleWindow"
                    :rules="rules.schedule"
                    ref="variable_SchedulingWindow"
                  ></v-text-field>                  
                </v-col>
              </v-row>
              <div class="headline pb-1 mt-5">Working Days</div>
              <v-divider class="ml-5 mb-3"></v-divider>
              <v-row class='ml-5 mt-5 mb-5'>
                <v-row>
                  <v-checkbox
                    v-model="localConfig.introConf.showWorkingDays"
                    label="Do you want customer to set working days for the video kyc calls?"
                  ></v-checkbox>
                </v-row>
                <v-row v-if="localConfig.introConf.showWorkingDays">
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <p>Default working days? (Rest would be offline days)</p>
                    <v-card
                      elevation="2"
                      outlined
                      rounded
                      class="ml-5 mr-5"
                      max-width="550"
                    >
                      <v-card-title primary-title class="justify-center">
                        <v-chip-group
                          v-model="localConfig.introConf.allowedWorkingDays"
                          multiple
                          column
                          ref="variable_WorkingDays"
                          active-class="blue darken-2 white--text text--accent-4"
                        >
                          <v-chip
                            label
                            ripple
                            v-for="day in weekDays"
                            :key="day"
                            filter
                            filter-icon="fas fa-check"
                          >
                            {{ day }}
                          </v-chip>
                        </v-chip-group>
                      </v-card-title>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <p>Set online hours during working days?</p>
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select
                          v-model="workingHourStarts"
                          :items="timeSlots"
                          label="Starts At (HH:MM)"
                          solo
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-select
                          v-model="workingHourEnds"
                          :items="timeSlots"
                          label="Ends At (HH:MM)"
                          solo
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>  
              </v-row>
              <div class="headline pb-1 mt-5">Non Working Dates</div>
              <v-divider class="ml-5 mb-3"></v-divider>
                <v-checkbox
                    v-model="localConfig.introConf.showNonWorkingDates"
                    label="Do you want customer to set non-working dates for the video kyc calls?"
                  ></v-checkbox>
                <div class="samplecsv" v-if="localConfig.introConf.showNonWorkingDates">
                  <p class="csvtemplate">
                    Download a 
                    <a href="#" @click.prevent="downloadSampleCSV('dates')">sample CSV template</a> 
                    to see an example of the format required.
                  </p>   
                </div>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="localConfig.introConf.showNonWorkingDates">
                  <v-file-input
                    ref="csvFileUpload"
                    id="csvFileUpload"
                    @click:clear="clearCSV()"
                    show-size
                    @change="uploadCSV('dates', $event)"
                    style="cursor: pointer"
                    :rules="rules.csvFileUpload"
                    hint="Only CSV files with size < 100KB"
                    :persistent-hint="true"
                    accept=".csv"
                    label="Upload New CSV File"
                  ></v-file-input>
                  <p class="filemessage" 
                    v-if="localConfig.introConf.datesFileName">
                    Last uploaded file: 
                    <span v-if="localConfig.introConf.datesFileURL" style="font-weight: bold; color: black;">
                      <a href="#" @click.prevent="downloadDatesCSV(localConfig.introConf.datesFileURL)">
                        {{ localConfig.introConf.datesFileName }}
                      </a> 
                    </span>
                    <span v-else style="font-weight: bold; color: black;">
                      {{ localConfig.introConf.datesFileName }}
                    </span>
                  </p>
                </v-col>              
            </div>   
            <div class="headline pb-1 mt-5">Debug Configurations</div>
            <v-divider class="ml-5 mb-3"></v-divider>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-checkbox
                    v-model="localConfig.introConf.isFrontendDebugLogs"
                    label="Enable Frontend debug logs"
                  ></v-checkbox>
                </v-col>
              </v-row>

            <div class="headline pb-1 mt-5">
              Video Conference Configurations
            </div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  v-model="localConfig.introConf.allowRejoin"
                  label="Enable the call rejoin option"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  v-model="localConfig.introConf.disableRejoinInCall"
                  label="Disable re-join option after Begin Video KYC is clicked"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  v-model="localConfig.introConf.disableJitsiLogs"
                  label="Disable Video Conference Event/Error logs in Mis-Report"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  v-model="localConfig.introConf.enableRecordingPreview"
                  label="Enable Video Recording Preview During Call"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" style="padding-top:0px;">
                <v-checkbox v-model="localConfig.introConf.startButtonTextRequired" label="Do you want to edit 'Begin Video KYC' button text?">
                </v-checkbox>
                <v-text-field v-if="localConfig.introConf.startButtonTextRequired" label="New text for 'Begin Video KYC' button"
                  v-model="localConfig.introConf.startButtonText" :rules="rules.startButtonTextChecks" ref="start_button_text"
                  @blur="() => {
                    if(this.localConfig.introConf.startButtonText)
                      this.localConfig.introConf.startButtonText = this.localConfig.introConf.startButtonText.trim()
                  }">
                </v-text-field>
              </v-col>
            </v-row>
            <div class="headline pb-1 mt-5">
              Chat Configurations
            </div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-checkbox
                  v-model="localConfig.introConf.enableChat"
                  label="Enable the Chat Feature"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="localConfig.introConf.enableChat">
              <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                <div>
                  <p>Banker</p>
                    <v-text-field
                      label="Background Color Of Message"
                      v-model="localConfig.introConf.bankerMessageBgColor"
                      @click="changeColorMsgBgBanker = true"
                      :rules="rules.hexCodeCheck"
                      ref="variable_bankerBgColor"
                    ></v-text-field>
                    <v-text-field
                      label="Text Color Of Message"
                      v-model="localConfig.introConf.bankerMessageTextColor"
                      @click="changeColorMsgTextBanker = true"
                      :rules="rules.hexCodeCheck"
                      ref="variable_bankerTextColor"
                    ></v-text-field>
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                <div>
                  <p>EndUser</p>
                   <v-text-field
                      label="Background Color Of Message"
                      v-model="localConfig.introConf.endUserMessageBgColor"
                      @click="changeColorMsgBgEndUser = true"
                      :rules="rules.hexCodeCheck"
                      ref="variable_endUserBgColor"
                    ></v-text-field>
                    <v-text-field
                      label="Text Color Of Message"
                      v-model="localConfig.introConf.endUserMessageTextColor"
                      @click="changeColorMsgTextEndUser = true"
                      :rules="rules.hexCodeCheck"
                      ref="variable_endUserTextColor"
                    ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  v-model="localConfig.introConf.reJoinCall"
                  label="Limit on rejoin frequency?"
                ></v-checkbox>
              </v-col>
            </v-row> -->

            <div class="headline pb-1 mt-5">
              Skilled Configurations
            </div>
            <v-divider class="ml-5 mb-3"></v-divider>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-checkbox
                    v-model="localConfig.introConf.allowIfSkillMatchFails"
                    label="Allow End user to procced as a skilled call if skill doesn't match with available skill records?"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-checkbox
                    v-model="localConfig.introConf.allowSkillOnFailure"
                    label="In case of any error related in skills fetching, allow end user to proceed?"
                  ></v-checkbox>
                </v-col>
              </v-row>

            <div class="headline pb-1 mt-5">User Experience Configurations</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-text-field
                  label="Desired Alias to be used"
                  v-model="localConfig.introConf.displayName"
                  :rules="[...rules.onlySpaceCheck, ...rules.specialCharsCheck]"
                  ref="variable_desiredAlias"
                ></v-text-field>
                <div style="background: #d0d0d0">
                  <v-img class="ma-4" aspect-ratio="3.4" :src="imageLogo" />
                </div>
                <v-file-input
                  id="fileUpload"
                  @click:clear="clearImage()"
                  show-size
                  @change="changeImage"
                  :rules="rules.fileUpload"
                  hint="Only Images (JPEG, JPG, PNG) with size < 200Kb"
                  :persistent-hint="true"
                  accept="image/png, image/jpeg, image/jpg"
                  label="Change Image"
                ></v-file-input>
                <v-text-field
                  label="Fallback static image to be loaded"
                  v-model="localConfig.introConf.fallBackImagePath"
                  :rules="rules.URL"
                  ref="variable_fallbackImage"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  v-model="localConfig.introConf.hideAppBar"
                  label="Do you want to hide AppBar?"
                ></v-checkbox>
                <v-checkbox
                  v-model="localConfig.introConf.hideBranding"
                  label="Do you want to hide 'Secured by Signzy' banner?"
                ></v-checkbox>
                <v-text-field
                  label="Color of top application bar."
                  v-model="localConfig.introConf.headerColor"
                  @click="changeColor = true"
                  :rules="[...rules.onlySpaceCheck,...rules.hexCodeCheck]"
                  ref="variable_headerColor"
                ></v-text-field>
                <v-text-field
                  label="End Color of top application bar."
                  v-model="localConfig.introConf.headerEndColor"
                  @click="changeColorEnd = true"
                  :rules="[...rules.onlySpaceCheck,...rules.hexCodeCheck]"
                  ref="variable_headerEndColor"
                ></v-text-field>
                <v-text-field
                  label="Set text color for end user"
                  v-model="localConfig.introConf.textColor"
                  @click="changeTextColor = true"
                  :rules="[...rules.onlySpaceCheck,...rules.hexCodeCheck]"
                  ref="variable_textColor"
                ></v-text-field>
                <v-text-field
                  label="Set button outline color for end user"
                  v-model="localConfig.introConf.buttonOutlineColor"
                  @click="changeButtonOutlineColor = true"
                  :rules="[...rules.onlySpaceCheck,...rules.hexCodeCheck]"
                  ref="variable_buttonOutlineColor"
                ></v-text-field>
                <v-text-field
                  label="Set button text color for end user"
                  v-model="localConfig.introConf.buttonTextColor"
                  @click="changeButtonTextColor = true"
                  :rules="[...rules.onlySpaceCheck,...rules.hexCodeCheck]"
                  ref="variable_buttonTextColor"
                ></v-text-field>
              </v-col>
            </v-row>
            <div class="text-right">
              <v-btn class="ma-0" type="submit" color="primary">Update</v-btn>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </v-col>
    <v-dialog v-model="alertMessage" persistent max-width="550">
      <v-card>
        <v-card-text>
          <p class="subtitle-1 pt-4 mb-0 pb-0">{{ message }}</p>
          <v-spacer></v-spacer>
          <div class="text-right">
            <v-btn dark small color="signzy_color" @click="alertMessage = false"
              >Ok</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="changeColor" persistent max-width="350">
      <v-card>
        <v-card-text>
          <p class="title pt-4 mb-0 pb-0" style="color: black">
            <v-btn class="mb-1" icon text @click="changeColor = false">
              <v-icon small>fas fa-arrow-left</v-icon> </v-btn
            >Choose a color
          </p>
          <v-spacer></v-spacer>
          <v-color-picker
            hide-mode-switch
            v-model="color"
            mode="hexa"
            class="ml-2 mr-2 mt-2"
          ></v-color-picker>
          <div class="text-right">
            <v-btn
              dark
              small
              color="signzy_color"
              @click="
                changeColor = false;
                localConfig.introConf.headerColor = color.hex;
              "
              >Ok</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="changeColorEnd" persistent max-width="350">
      <v-card>
        <v-card-text>
          <p class="title pt-4 mb-0 pb-0" style="color: black">
            <v-btn class="mb-1" icon text @click="changeColorEnd = false">
              <v-icon small>fas fa-arrow-left</v-icon> </v-btn
            >Choose a color
          </p>
          <v-spacer></v-spacer>
          <v-color-picker
            hide-mode-switch
            v-model="colorEnd"
            mode="hexa"
            class="ml-2 mr-2 mt-2"
          ></v-color-picker>
          <div class="text-right">
            <v-btn
              dark
              small
              color="signzy_color"
              @click="
                changeColorEnd = false;
                localConfig.introConf.headerEndColor = colorEnd.hex;
              "
              >Ok</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="changeTextColor" persistent max-width="350">
      <v-card>
        <v-card-text>
          <p class="title pt-4 mb-0 pb-0" style="color: black">
            <v-btn class="mb-1" icon text @click="changeTextColor = false">
              <v-icon small>fas fa-arrow-left</v-icon> </v-btn
            >Choose a color
          </p>
          <v-spacer></v-spacer>
          <v-color-picker
            hide-mode-switch
            v-model="colorText"
            mode="hexa"
            class="ml-2 mr-2 mt-2"
          ></v-color-picker>
          <div class="text-right">
            <v-btn
              dark
              small
              color="signzy_color"
              @click="
                changeTextColor = false;
                localConfig.introConf.textColor = colorText.hex;
              "
              >Ok</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="changeButtonOutlineColor" persistent max-width="350">
      <v-card>
        <v-card-text>
          <p class="title pt-4 mb-0 pb-0" style="color: black">
            <v-btn class="mb-1" icon text @click="changeButtonOutlineColor = false">
              <v-icon small>fas fa-arrow-left</v-icon> </v-btn
            >Choose a color
          </p>
          <v-spacer></v-spacer>
          <v-color-picker
            hide-mode-switch
            v-model="colorButtonOutline"
            mode="hexa"
            class="ml-2 mr-2 mt-2"
          ></v-color-picker>
          <div class="text-right">
            <v-btn
              dark
              small
              color="signzy_color"
              @click="
                changeButtonOutlineColor = false;
                localConfig.introConf.buttonOutlineColor = colorButtonOutline.hex;
              "
              >Ok</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="changeButtonTextColor" persistent max-width="350">
      <v-card>
        <v-card-text>
          <p class="title pt-4 mb-0 pb-0" style="color: black">
            <v-btn class="mb-1" icon text @click="changeButtonTextColor = false">
              <v-icon small>fas fa-arrow-left</v-icon> </v-btn
            >Choose a color
          </p>
          <v-spacer></v-spacer>
          <v-color-picker
            hide-mode-switch
            v-model="colorButtonText"
            mode="hexa"
            class="ml-2 mr-2 mt-2"
          ></v-color-picker>
          <div class="text-right">
            <v-btn
              dark
              small
              color="signzy_color"
              @click="
                changeButtonTextColor = false;
                localConfig.introConf.buttonTextColor = colorButtonText.hex;
              "
              >Ok</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="changeColorMsgBgEndUser" persistent max-width="350">
      <v-card>
        <v-card-text>
          <p class="title pt-4 mb-0 pb-0" style="color: black">
            <v-btn class="mb-1" icon text @click="changeColorMsgBgEndUser = false">
              <v-icon small>fas fa-arrow-left</v-icon> </v-btn
            >Choose a color
          </p>
          <v-spacer></v-spacer>
          <v-color-picker
            hide-mode-switch
            v-model="colorEndUserMsgBg"
            mode="hexa"
            class="ml-2 mr-2 mt-2"
          ></v-color-picker>
          <div class="text-right">
            <v-btn
              dark
              small
              color="signzy_color"
              @click="
                changeColorMsgBgEndUser = false;
                localConfig.introConf.endUserMessageBgColor = colorEndUserMsgBg.hex;
              "
              >Ok</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="changeColorMsgBgBanker" persistent max-width="350">
      <v-card>
        <v-card-text>
          <p class="title pt-4 mb-0 pb-0" style="color: black">
            <v-btn class="mb-1" icon text @click="changeColorMsgBgBanker = false">
              <v-icon small>fas fa-arrow-left</v-icon> </v-btn
            >Choose a color
          </p>
          <v-spacer></v-spacer>
          <v-color-picker
            hide-mode-switch
            v-model="colorBankerMsgBg"
            mode="hexa"
            class="ml-2 mr-2 mt-2"
          ></v-color-picker>
          <div class="text-right">
            <v-btn
              dark
              small
              color="signzy_color"
              @click="
                changeColorMsgBgBanker = false;
                localConfig.introConf.bankerMessageBgColor = colorBankerMsgBg.hex;
              "
              >Ok</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="changeColorMsgTextBanker" persistent max-width="350">
      <v-card>
        <v-card-text>
          <p class="title pt-4 mb-0 pb-0" style="color: black">
            <v-btn class="mb-1" icon text @click="changeColorMsgTextBanker = false">
              <v-icon small>fas fa-arrow-left</v-icon> </v-btn
            >Choose a color
          </p>
          <v-spacer></v-spacer>
          <v-color-picker
            hide-mode-switch
            v-model="colorBankerMsgText"
            mode="hexa"
            class="ml-2 mr-2 mt-2"
          ></v-color-picker>
          <div class="text-right">
            <v-btn
              dark
              small
              color="signzy_color"
              @click="
                changeColorMsgTextBanker = false;
                localConfig.introConf.bankerMessageTextColor = colorBankerMsgText.hex;
              "
              >Ok</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="changeColorMsgTextEndUser" persistent max-width="350">
      <v-card>
        <v-card-text>
          <p class="title pt-4 mb-0 pb-0" style="color: black">
            <v-btn class="mb-1" icon text @click="changeColorMsgTextEndUser = false">
              <v-icon small>fas fa-arrow-left</v-icon> </v-btn
            >Choose a color
          </p>
          <v-spacer></v-spacer>
          <v-color-picker
            hide-mode-switch
            v-model="colorEndUserMsgText"
            mode="hexa"
            class="ml-2 mr-2 mt-2"
          ></v-color-picker>
          <div class="text-right">
            <v-btn
              dark
              small
              color="signzy_color"
              @click="
                changeColorMsgTextEndUser = false;
                localConfig.introConf.endUserMessageTextColor = colorEndUserMsgText.hex;
              "
              >Ok</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { uploadFile, downloadFile } from "@/flux/persist.flux";
import {convertTo24HourFormat, convertTo12HourFormat} from "@/Plugins/utils.js"
import {
  base_url,
  end_points,
  vcip_end_points,
  host,
} from "@/config";
import axios from "axios";
let eventBus = window.eventBus;
let store = window.store;
export default {
  data: () => ({
    alertMessage: false,
    message: "",
    isVideoKYC: false,
    customerId: '',
    isVideoConference: false,
    showScheduling: false,
    currentFlow: "",
    localConfig: {
      introConf:{
        isRecording: true,
        enablePool: false
      }
    },
    isRecording: true,
    enablePoolFeature: true,
    imageLogo: "",
    pincodeFileName: "",
    datesFileName: "",
    imagePersistUrl: "",
    changeColor: false,
    changeColorEnd: false,
    changeTextColor: false,
    changeButtonOutlineColor: false,
    changeButtonTextColor: false,
    changeColorMsgBgEndUser: false,
    changeColorMsgBgBanker: false,
    changeColorMsgTextBanker: false,
    changeColorMsgTextEndUser: false,
    videoCallDetailsHeader: 'KYC Data',
    color: {},
    colorEnd: {},
    colorText: {},
    colorButtonOutline:{},
    colorButtonText:{},
    colorEndUserMsgBg: {},
    colorBankerMsgBg: {},
    colorBankerMsgText: {},
    colorEndUserMsgText:{},
    allowedSchedulingDays: [0,1,2,3,4,5,6],
    allowedWorkingDays: [0,1,2,3,4,5,6],
    weekDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    noWeekDaysSelected: false,
    schedulingDaysNotValid: false,
    defaultQueuingType: "advancedQueuing",
    workingHourStarts: '',
    workingHourEnds: '',
    queuingTypes: [
      {
        label:"Advanced Queuing",
        value: "advancedQueuing"
      },
      {
        label: "Independent Queueing",
        value: "independentQueuing"
      }
    ],
    countryList: require("@/assets/country-data.json"),
    imageCleared: false,
    csvCleared: false,
    inValidCSV: false,
    sampleCSVContent: "pincode\n123456\n654321\n111222\n333444",
    sampleDatesCSVContent: "01/01/2024\n02/01/2024\n03/01/2024\n04/01/2024",
    videoQualityMap: [
      {
        text: "Low",
        value: "vga"
      },
      {
        text: "Medium",
        value: "qhd"
      },
      {
        text: "High",
        value: "hd"
      },
      {
        text: "Auto Scale",
        value: "auto"
      }
    ],
    compressionMap: [
      {
        text: "Ultra low compression",
        value: "ultrafast",
      },
      {
        text: "Low Compression",
        value: "veryfast",
      },
      {
        text: "Medium Compression",
        value: "faster",
      },
      {
        text: "Good Compression",
        value: "medium",
      },
      {
        text: "Better Compression",
        value: "slow",
      },
      {
        text: "Best Compression",
        value: "veryslow",
      },
    ],
    languages: ["HINDI", "KANNADA", "BENGALI", "GUJARATI", "MALAYALAM", "MARATHI", "PUNJABI", "TAMIL", "TELUGU"],
    timeSlots: [],
    rules: {
      fileUpload : [
        (value) =>
          !value ||
          value.type === "image/jpg" || value.type === "image/jpeg" || value.type === "image/png" ||
          "Only jpeg, jpg and png files are allowed",
        (value) =>
          !value ||
          value.size < 200000 ||
          "Image size should be less than 200KB!"
      ],
      csvFileUpload: [
      (value) =>
        !value ||
        (value.type === "application/vnd.ms-excel" || value.type === "text/csv") ||
        "Only CSV files are allowed",
      (value) =>
        !value ||
        value.size < 100000 ||
        "File size should be less than 100KB!",
    ],

      spaceInputCheck: [
          (value) => value && value.replace(/\s/g, '').length || "Please enter valid value, cannot take only spaces"
      ],
      onlySpaceCheck: [
        (value) => !/^\s+$/.test(value) || "Please enter valid value, cannot take only spaces"
      ],
      alphanumCheck : [
        (value) => /^([a-zA-Z0-9 _-]*)$/.test(value) || "Can take only alphanumeric, underscore and hyphen",
        (value) => !/^\s+$/.test(value) || "Please enter valid value, cannot take only spaces"
      ],
      textCheck : [
        (value) =>  !!value || "Cannot leave this field empty",
        (value) => !/^\s+$/.test(value) || "Please enter valid value, cannot take only spaces"
        // (value) => value && value.replace(/\s/g, '').length || "Please enter valid value, cannot take only spaces"
      ],
      character20Length: [
        (value) => !value.match(/[()~`<>*]/) || "Cannot take  ( ) ~ ` < > * in input",
        (value) => (value.length < 20) || "max character limit is 20",
      ],
      characterLongText: [
        (value) => !!value || "Cannot leave this field empty",
        (value) => (value && !value.match(/[()~`<>*]/)) || "Cannot take  ( ) ~ ` < > * in input",
        (value) => (value && (value.length < 100)) || "max character limit is 100",
        (value) => value && value.replace(/\s/g, '').length || "Please enter valid value, cannot take only spaces"
      ],
      timeCheck : [
        (value) =>
          !!value ||
          "Cannot be left empty!",
        (value) =>
         !/\D/.test(value) ||
          "Can take only numeric values",
        (value) =>
          !(parseInt(value) < 1 )||
          "Cannot be less than a minute",
        (value) =>
          !(parseInt(value) > 60 )||
          "Cannot be more than 60 minutes"
      ],
      pollingCheck : [
        // (value) =>
        //   !!value ||
        //   "Cannot be left empty!",
        (value) =>
         !/\D/.test(value) ||
          "Can take only numeric values",
        (value) =>
          !(parseInt(value) < 1 )||
          "Cannot be less than a second",
        (value) =>
          !(parseInt(value) > 60 )||
          "Cannot be more than 60 seconds"
      ],
      timeCheckSeconds : [
        (value) =>
          !!value ||
          "Cannot be left empty!",
        (value) =>
          !/\D/.test(value) ||
          "Can take only numeric values",
        (value) =>
          !(parseInt(value) <= 4) ||
          "Call timeout duration should always be greater than 4 seconds"
      ],
      timeCheckHours : [
        (value) =>
        !!value ||
          "Cannot be left empty!",
        (value) =>
         !/\D/.test(value) ||
          "Can take only numeric values",
        (value) =>
          !(parseInt(value) < 1 )||
          "Cannot be less than a 1 hour",
        (value) =>
          !(parseInt(value) > 180 )||
          "Cannot be more than 180 hours"
      ],
      checkEmpty : [
        (value) =>
          !(value.length < 1) ||
          "Minimum one country is required for whitelisting!"
      ],
      loginPasswordFieldChecks: [
        (value) => !(value == 0) || "Days entered cannot be zero",
        (value) =>
          !/\D/.test(value) ||
          "Can take only numeric values",
      ],
      hexCodeCheck: [
        (value) => !value || /^#[0-9A-F]{6}$/i.test(value) || "Invalid Hex Code"

      ],
      specialCharsCheck: [
        (value) => {
          if(value && value != ''){
            if(value.match(/[()~`<>*]/)){
              return "Cannot take  ( ) ~ ` < > * in input"
            }
          }
          return true;
        }
      ],
      URL : [
        (value) => {
          if (value === "" || !value) {
            return true;
          }
          let regex = new RegExp(
            /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
          );
          if (!value.match(regex)) {
            return "Enter a valid image URL!";
          }
          return true;
        }
      ],
      checkDays : [
        (value) => {
          if (value === "" ) {
            return true
          }
          if (/\D/.test(value)) {
            return "Can take only numeric values";
          }
          return true;
        }
      ],
      thresholdValidationRules: [
      (value) => {
        if (value === "") {
          return "This field is required";
        }
        if (isNaN(value)) {
          return "Can take only numeric values";
        }
        if (parseInt(value) <= 0) {
          return "Cannot be less than or equal to 0 km";
        }
        if (parseInt(value) > 1000) {
          return "Cannot be more than 1000 km";
        }
        return true;
      },
    ],
      schedule: [
        (value) => {
          if (value === "" ) {
            return "Cannot be empty"
          }
          if (/\D/.test(value)) {
            return "Can take only numeric values";
          }
          if (parseInt(value) < 1) {
            return "Cannot be less than 1 day"
          }
          if (parseInt(value) > 60) {
            return "Cannot be more than 60 days"
          }
          return true;
        }
      ],
      startButtonTextChecks: [
        (value) => !!value || "Cannot leave this empty",
        (value) => !/^\s+$/.test(value) || "Cannot take only spaces",
        (value) => !(value && value.length > 30) || "Only 30 characters are allowed",
        (value) => {
          let regex = new RegExp(/^[ a-zA-Z][a-zA-Z0-9 ]*$/);
          if (value && !value.match(regex)) {
            return "Only alphanumeric values that starts with an alphabet are allowed";
          }
          return true;
        }
      ],
      minSpeed: [
        (value) =>
          !/\D/.test(value) ||
          "Can take only numeric values",
        (value) =>
          !(parseInt(value) < 200) ||
          "Internet Speed cannot be less than 200kbps"
      ]
    },
    localCopy: {},
    updatedSuccessfully: false,
  }),
  methods: {
    generateTimeSlots() {
        const timeSlots = [];
        const totalMinutesInDay = 24 * 60; // Total minutes in a day
        const interval = 15; // Interval in minutes

        for (let minutes = 0; minutes < totalMinutesInDay; minutes += interval) {
          let hours = Math.floor(minutes / 60);
          const mins = minutes % 60;
          const ampm = hours >= 12 ? 'PM' : 'AM';

          // Convert 24-hour time to 12-hour time
          hours = hours % 12;
          hours = hours ? hours : 12; // The hour '0' should be '12'

          // Format hours and minutes to HH:MM
          const formattedHours = String(hours).padStart(2, '0');
          const formattedMinutes = String(mins).padStart(2, '0');
          
          timeSlots.push(`${formattedHours}:${formattedMinutes} ${ampm}`);
        }

        return timeSlots;
    },
    // updateFilteredEndTimeSlots(startTime) {
    //   if (!startTime) {
    //     this.filteredEndTimeSlots = this.timeSlots;
    //     return;
    //   }

    //   const startTimeIndex = this.timeSlots.indexOf(startTime);
    //   this.filteredEndTimeSlots = this.timeSlots.slice(startTimeIndex + 1);
    // },
    updateShowSchedule() {
      this.showScheduling = this.localConfig.introConf.showScheduling;
    },
    clearImage() {
      this.imageCleared = true;
      this.imageLogo =
        this.localConfig.introConf.imageLogo || require("@/assets/signzy.png");
    },
    clearCSV() {
      this.csvCleared = true;
    },
    validateFields(ref, typeOfValidation) {
      if (typeOfValidation === "alphaNumeric") {
        if (!ref.value || !ref.value.length) {
          return true;
        }
        if (ref.value && !ref.value.replace(/\s/g, '').length) {
          return true;
        }
        return false;
      } else if(typeOfValidation === "onlySpacesInput"){
        if (ref.value && !ref.value.replace(/\s/g, '').length) {
          return true;
        }
        if (ref.value && (/^\s+$/).test(ref.value)) {
          return true;
        }
        return false;
      } else if(typeOfValidation === "alphaNum") {
        if(!(/^([a-zA-Z0-9 _-]*)$/.test(ref.value))) {
          return true;
        }
        if (ref.value && !ref.value.replace(/\s/g, '').length) {
          return true;
        }
        if (ref.value && (/^\s+$/).test(ref.value)) {
          return true;
        }
        return false;
      } else if (typeOfValidation === "numeric") {
        if (!ref.value || !ref.value.toString().length) {
          return true;
        }
        if (/\D/.test(ref.value)) {
          return true;
        }
        if (ref.value < 1) {
          return true
        }
        if (ref.value > 60) {
          return true
        }
        return false;
      }
      else if (typeOfValidation === "callExpiry") {
        if (!ref.value || !ref.value.toString().length) {
          return true;
        }
        if (/\D/.test(ref.value)) {
          return true;
        }
        if (ref.value < 1) {
          return true
        }
        if (ref.value > 180) {
          return true
        }
        return false;
      } else if (typeOfValidation == "characterLimit20") {
        if (ref.value && ref.value.length > 20) {
          return true;
        }
        if (ref.value.match(/[()~`<>*]/)) {
          return true;
        }
        if (ref.value && !ref.value.replace(/\s/g, '').length) {
          return true;
        }
        if (ref.value && (/^\s+$/).test(ref.value)) {
          return true;
        }
        return false;
      } else if (typeOfValidation == "characterLimit100") {
        if (ref && ref.value && ref.value.length > 100) {
          return true;
        }
        if (ref && ref.value && ref.value.match(/[()~`<>*]/)) {
          return true;
        }
        if (ref && ref.value && !ref.value.replace(/\s/g, '').length) {
          return true;
        }
        if (ref.lazyValue == "" || ref.lazyValue.match(/[()~`<>*]/)) {
          return true;
        }
        return false;
      } else if (typeOfValidation == "specialCharsCheck") {
        if (ref.value && ref.value.match(/[()~`<>*]/)) {
          return true;
        }
        if (ref.value && !ref.value.replace(/\s/g, '').length) {
          return true;
        }
        return false;
      }
       else if (typeOfValidation === "minSpeed") {
        if (/\D/.test(ref.value)) {
          return true;
        }
        if (ref.value && ref.value < 200) {
          return true
        }
        return false;
      } else if (typeOfValidation === "numericSeconds") {
        if (!ref.value || !ref.value.toString().length) {
          return true;
        }
        if (/\D/.test(ref.value)) {
          return true;
        }
        if (ref.value <= 4) {
          return true;
        }
        return false;
      } else if (typeOfValidation === "checkEmpty") {
        if (ref.value.length < 1) {
          return true;
        }
        return false;
      } 
      else if (typeOfValidation === "checkEmptyDistance") {
        if (!ref.value || !ref.value.toString().length) {
          return true;
        }
        if (/\D/.test(ref.value)) {
          return true;
        }
      } 
      else if (typeOfValidation === "checkURL") {
        if (ref.value === "" || ref.value.length === 0) {
          return false;
        }
        if (!this.urlChecker(ref.value)) {
          return true;
        }
        return false;
      } else if (typeOfValidation === "scheduleExp") {
        if (/\D/.test(ref.value)) {
          return true;
        }
        return false;
      } else if(typeOfValidation === "hexCode") {
        if(!ref.value){
          return false;
        }
        if(!(/^#[0-9A-F]{6}$/i.test(ref.value))) {
          return true;
        }
      } else if(typeOfValidation === "schedulingDays") {
        if(ref.value && ref.value.length === 0){
          this.showAlert("At least 1 working day should be selected for rescheduling");
          return true;
        }
        return false;
      } else if(typeOfValidation === "workingDays") {
        if(ref.value && ref.value.length === 0){
          this.showAlert("At least 1 working day should be selected for rescheduling");
          return true;
        }
        return false;
      } else if(typeOfValidation === "startButtonTextCheck") {
        if (!ref.value || ref.value.length < 1) {
          return true;
        }
        if (ref.value.length > 30) {
          return true;
        }
        if (ref.value && !ref.value.replace(/\s/g, '').length) {
          return true;
        }
        let regex = new RegExp(/^[ a-zA-Z][a-zA-Z0-9 ]*$/);
        if (ref.value && !ref.value.match(regex)) {
          return true;
        }
        if(this.localConfig.introConf.startButtonText){
          this.localConfig.introConf.startButtonText = this.localConfig.introConf.startButtonText.trim();
        }
      } else {
        return false;
      }
    },
    urlChecker(url) {
      let regex = new RegExp(
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
      );
        if (!url.match(regex)) {
          return false;
        }
      return true;
    },
    getFlowData(){
      if(this.currentFlow != "default"){
        if(!this.config.multiflow[this.currentFlow]){
          //deep copy
          let {multiflow , ...dummy} = {...this.config};
          this.config.multiflow[this.currentFlow] = JSON.parse(JSON.stringify(dummy));
        }
        if(!this.config.multiflow[this.currentFlow].introConf.hasOwnProperty('allowedSchedulingDays')){
          this.config.multiflow[this.currentFlow].introConf.allowedSchedulingDays = this.allowedSchedulingDays;
        }
        if(!this.config.multiflow[this.currentFlow].introConf.hasOwnProperty('priorityQueuingType')){
          this.config.multiflow[this.currentFlow].introConf.priorityQueuingType = this.defaultQueuingType;
        }
        if(!this.config.multiflow[this.currentFlow].introConf.hasOwnProperty('allowedWorkingDays')) {
          this.config.multiflow[this.currentFlow].introConf.allowedWorkingDays = this.allowedWorkingDays;
        }
        if(this.config.multiflow[this.currentFlow].introConf.hasOwnProperty('workingHourStarts')) {
          this.workingHourStarts =  convertTo12HourFormat(this.config.multiflow[this.currentFlow].introConf.workingHourStarts);
        }
        if(this.config.multiflow[this.currentFlow].introConf.hasOwnProperty('workingHourEnds')) {
          this.workingHourEnds =  convertTo12HourFormat(this.config.multiflow[this.currentFlow].introConf.workingHourEnds);
        }
        this.localConfig = this.config.multiflow[this.currentFlow];
        this.localCopy.introConf = JSON.parse(JSON.stringify(this.localConfig.introConf));   // creating a deep copy of config for local referencing
      } else{
        if(!this.config.introConf.hasOwnProperty('allowedSchedulingDays')){
          this.config.introConf.allowedSchedulingDays = this.allowedSchedulingDays;
        }
        if(!this.config.introConf.hasOwnProperty('priorityQueuingType')){
          this.config.introConf.priorityQueuingType = this.defaultQueuingType;
        }
        if(!this.config.introConf.hasOwnProperty('allowedWorkingDays')) {
          this.config.introConf.allowedWorkingDays = this.allowedWorkingDays;
        }
        if(this.config.introConf.hasOwnProperty('workingHourStarts')) {
          this.workingHourStarts =  convertTo12HourFormat(this.config.introConf.workingHourStarts);
        }
        if(this.config.introConf.hasOwnProperty('workingHourEnds')) {
          this.workingHourEnds =  convertTo12HourFormat(this.config.introConf.workingHourEnds);
        }
        this.localConfig = this.config;
       
        this.localCopy.introConf = JSON.parse(JSON.stringify(this.config.introConf));   // creating a deep copy of config for local referencing
      }

      eventBus.$emit("enablePool", {
        flag: this.localConfig.introConf.enablePool ?? false,
        flow: this.currentFlow != "default" ? this.currentFlow : "default"
      });

    },
    handleEnableIpInfoChange() {
      if (!this.localConfig.introConf.enableIpInfo) {
        delete this.localConfig.introConf.thresholdPermanentToIp;
        delete this.localConfig.introConf.thresholdIpToBrowser;
      }
    },
    async uploadCSV(type, file) {
      if (!this.csvCleared) {
        if (
          file &&
          file.size < 100000 &&
          (file.type === "application/vnd.ms-excel" ||
            file.type === "text/csv")
        ) {
          try {
            const reader = new FileReader();
            reader.onload = async (e) => {
              const data = e.target.result;
              const isValidCSV = await this.validateCSVContent(data, type);

              if (!isValidCSV) {
                this.inValidCSV = true;
                setTimeout(() => {
                  this.$refs.csvFileUpload.reset();
                }, 1000);
                console.log("not valid csv format");
              } else {
                if (type == 'pincode') {
                  this.pincodeFileName = file.name;
                  this.inValidCSV = false;
                  let formData = new FormData();
                  formData.append("file", file);

                  const response = await axiosInstance.post(base_url + end_points.upload_pincode, formData);
                  if (response.data.status === "success") {
                    console.log('File uploaded successfully');
                  } else {
                    console.error('File upload failed:', response.data.message);
                  }
                } else {
                  this.datesFileName = file.name;
                  let formData = new FormData();
                  formData.append("file", file);
                  formData.append("ttl", "3 years");
                  let datesFileURL = await uploadFile({
                    url: base_url + end_points.upload_files,
                    data: formData,
                    token: this.$store.getters.userData.token,
                    secretKey: this.$store.getters.envValues.secretKey
                  });
                  this.$set(this.localConfig.introConf, 'nonWorkingDates', data.trim().split('\n'));
                  this.$set(this.localConfig.introConf, 'datesFileURL', datesFileURL);
                }
              }
            };
            reader.readAsText(file);
          } catch (error) {
            console.error('Error reading file:', error);
          }
        }
      } else {
        this.csvCleared = false;
      }
    },
    async validateCSVContent(data, type) {
      let error = '';

      if (data.trim().length === 0) {
        error = 'CSV file is empty';
        eventBus.$emit("vueSnack", error);
        console.error(error);
        return false;
      }

      const rows = data.split('\n').map(row => row.trim()).filter(row => row !== '');

      if (rows.length === 0) {
        error = 'CSV file is empty';
        eventBus.$emit("vueSnack", error);
        console.error(error);
        return false;
      }
      if(type == 'pincode') {
        const headers = rows[0].trim().split(',');
        if (headers.length !== 1 || headers[0].trim() !== 'pincode') {
          error = 'Invalid CSV header. Expected header: "pincode"';
          eventBus.$emit("vueSnack", error);
          console.error(error);
          return false;
        }
        if (rows.length === 1) {
          error = 'CSV file contains only header and no pincodes';
          eventBus.$emit("vueSnack", error);
          console.error(error);
          return false;
        }
        for (let i = 1; i < rows.length; i++) {
          const row = rows[i];
          if (!/^\d{6}$/.test(row)) {
            error = `Invalid pincode at row ${i + 1}. Expected a 6-digit pincode.`;
            eventBus.$emit("vueSnack", error);
            console.error(error);
            return false;
          }
        }
      } else if (type === 'dates') {
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i].trim();
          
          // Check if the row is empty
          if (row === '') {
            const error = `Empty row at row ${i + 1}.`;
            console.error(error);
            eventBus.$emit("vueSnack", error);
            return false;
          }

          const columns = row.split(',');

          if (columns.length > 1) {
            const error = `Invalid format at row ${i + 1}. Rows should contain only one column.`;
            console.error(error);
            eventBus.$emit("vueSnack", error);
            return false;
          }

          if (columns.length > 0) {
            const firstColumnValue = columns[0].trim();

            // Check if the first column contains a valid date in dd/mm/yyyy format
            if (!/^\d{2}\/\d{2}\/\d{4}$/.test(firstColumnValue)) {
              error = `Invalid date format at row ${i + 1}. Expected a date in dd/mm/yyyy format in the first column enclosed in double quotes.`;
              console.error(error);
              eventBus.$emit("vueSnack", error);
              return false;
            }

            // Check if any other column contains a date in dd/mm/yyyy format
            for (let j = 1; j < columns.length; j++) {
              const columnValue = columns[j].trim();

              if (columnValue !== '' && /^\d{2}\/\d{2}\/\d{4}$/.test(columnValue)) {
                error = `Invalid date format at row ${i + 1}. Only the first column should contain a date in dd/mm/yyyy format.`;
                console.error(error);
                eventBus.$emit("vueSnack", error);
                return false;
              }
            }
          } else {
            error = `Invalid CSV format at row ${i + 1}.`;
            console.error(error);
            eventBus.$emit("vueSnack", error);
            return false;
          }
        }
      } else {}
      
      return true;
    },
    downloadSampleCSV(type) {
      const blob = new Blob([type == 'dates' ? this.sampleDatesCSVContent : this.sampleCSVContent], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'sample.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    async downloadDatesCSV(csvURL) {
      try {
        const response = await downloadFile(csvURL);

        if (response && response.data && response.data.file) {
          const fileContent = atob(response.data.file);
          const blob = new Blob([fileContent], { type: 'text/csv;charset=utf-8;' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = this.localConfig.introConf.datesFileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        } else {
          console.error('Invalid response format:', response);
        }
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    },
    async changeImage(file) {
      //if (this.errorOcured) {
      if (!this.imageCleared) {
        if (
          file &&
          file.size < 200000 &&
          (file.type == "image/jpeg" ||
            file.type == "image/jpg" ||
            file.type == "image/png")
        ) {
          let formData = new FormData();
          formData.append("file", file);
          formData.append("ttl", "3 years");
          this.imagePersistUrl = await uploadFile({
            url: base_url + end_points.upload_files,
            data: formData,
            token: this.$store.getters.userData.token,
            secretKey: this.$store.getters.envValues.secretKey
          });
          if (this.imagePersistUrl) {
            let imageDataResp = await downloadFile(this.imagePersistUrl);
            this.imageLogo = `data:${imageDataResp.headers["content-type"].split(";")[0]};base64, ${imageDataResp.data.file}`
            this.imageCleared = false;
          } else {
            console.log("using fallback")
            this.imageLogo = require("@/assets/signzy.png");
          }
        }
      } else {
        this.imageCleared = false;
      }
      // } else {
      //   eventBus.$emit("vueSnack", "There are some errors while uploading!");
      // }
    },
    showAlert(message) {
      this.message = message;
      this.alertMessage = true;
    },
    startTimeLessThanEndTime() {
      if(this.localConfig.introConf.endsAt && this.localConfig.introConf.startsAt){
          let endTime = this.localConfig.introConf.endsAt
            .split(":")
            .map((i) => parseInt(i));

          let startTime = this.localConfig.introConf.startsAt
            .split(":")
            .map((i) => parseInt(i));

          return startTime[0] * 60 + startTime[1] < endTime[0] * 60 + endTime[1];

      }else{
        return false;
      }
    },
    highLighter(element) {
      // element passed is a ref
      element.focus();
      element.blur();
    },
    submitForm() {
      //Cheking imageLogo
      if (this.imageLogo) {
        this.localConfig.introConf.imageLogo = this.imagePersistUrl;
      }
      if(this.pincodeFileName) {
        this.localConfig.introConf.pincodeFileName = this.pincodeFileName;
      }
      if(this.datesFileName) {
        this.localConfig.introConf.datesFileName = this.datesFileName;
      }
      this.localConfig.introConf.videoCallDetailsHeader = this.videoCallDetailsHeader;

      if (this.isVideoKYC) {
        this.localConfig.introConf.isScreenshare = this.isScreenshare
          ? this.localConfig.introConf.isScreenshare
          : false;
        this.localConfig.introConf.isRecording = this.isRecording
          ? this.localConfig.introConf.isRecording
          : false;
        if (
          this.validateFields(this.$refs.variable_AutoHangUpTime, "numericSeconds")
        ) {
          this.highLighter(this.$refs.variable_AutoHangUpTime); //admin call timeout
        } else if(
          this.localConfig.introConf.officialAssignedMessage &&
          this.validateFields(this.$refs.variable_officialAssignedMessage, "alphaNum")
        ) {
          this.highLighter(this.$refs.variable_officialAssignedMessage);
        } else if ( this.localConfig.introConf && (this.localConfig.introConf.startsAt || this.localConfig.introConf.showScheduling) &&
          !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(
            this.localConfig.introConf.startsAt
          )
        ) {
          this.showAlert(
            "Not a valid start time, please enter in 24hrs, i.e, HH:MM"
          );
        } else if (
            this.localConfig.introConf.instructionHeader &&
            this.validateFields(this.$refs.variable_InstructionHeader, 'onlySpacesInput')
          ){
            this.highLighter(this.$refs.variable_InstructionHeader);
          } else if(this.localConfig.introConf.waitingMessageRequired && this.validateFields(this.$refs.variable_waitingMessage, 'characterLimit100')){
            this.highLighter(this.$refs.variable_waitingMessage);
          } else if (
            this.localConfig.introConf.instructionHeader &&
            this.validateFields(this.$refs.variable_InstructionHeader, 'specialCharsCheck')
          ){
            this.highLighter(this.$refs.variable_InstructionHeader);
          }else if(this.localConfig.introConf.displayName &&
            this.validateFields(this.$refs.variable_desiredAlias, 'onlySpacesInput')
          ){
              this.highLighter(this.$refs.variable_desiredAlias);
          } else if(this.localConfig.introConf.displayName &&
            this.validateFields(this.$refs.variable_desiredAlias, 'specialCharsCheck')
          ){
              this.highLighter(this.$refs.variable_desiredAlias);
          }else if (
            this.localConfig.introConf.headerColor &&
            this.validateFields(this.$refs.variable_headerColor, 'onlySpacesInput')
          ){
            this.highLighter(this.$refs.variable_headerColor);
          }else if (
            this.localConfig.introConf.headerColor &&
            this.validateFields(this.$refs.variable_headerColor, 'hexCode')
          ){
            this.highLighter(this.$refs.variable_headerColor);
          }else if (
            this.localConfig.introConf.headerEndColor &&
            this.validateFields(this.$refs.variable_headerEndColor, 'onlySpacesInput')
          ){
            this.highLighter(this.$refs.variable_headerEndColor);
          }else if (
            this.localConfig.introConf.headerEndColor &&
            this.validateFields(this.$refs.variable_headerEndColor, 'hexCode')
          ){
            this.highLighter(this.$refs.variable_headerEndColor);
          }else if (
            this.localConfig.introConf.textColor &&
            this.validateFields(this.$refs.variable_textColor, 'onlySpacesInput')
          ){
            this.highLighter(this.$refs.variable_textColor);
          }else if (
            this.localConfig.introConf.textColor &&
            this.validateFields(this.$refs.variable_textColor, 'hexCode')
          ){
            this.highLighter(this.$refs.variable_textColor);
          }else if (
            this.localConfig.introConf.buttonOutlineColor &&
            this.validateFields(this.$refs.variable_buttonOutlineColor, 'onlySpacesInput')
          ){
            this.highLighter(this.$refs.variable_buttonOutlineColor);
          }else if (
            this.localConfig.introConf.buttonOutlineColor &&
            this.validateFields(this.$refs.variable_buttonOutlineColor, 'hexCode')
          ){
            this.highLighter(this.$refs.variable_buttonOutlineColor);
          }else if (
            this.localConfig.introConf.buttonTextColor &&
            this.validateFields(this.$refs.variable_buttonTextColor, 'onlySpacesInput')
          ){
            this.highLighter(this.$refs.variable_buttonTextColor);
          }else if (
            this.localConfig.introConf.buttonTextColor &&
            this.validateFields(this.$refs.variable_buttonTextColor, 'hexCode')
          ){
            this.highLighter(this.$refs.variable_buttonTextColor);
          }else if (
          this.localConfig.introConf.showScheduling &&
          this.validateFields( this.$refs.variable_SchedulingWindow , "numeric")
        ) {
          this.highLighter(this.$refs.variable_SchedulingWindow);
        } else if (
          this.localConfig.introConf.videoCallDetailsHeader &&
          this.validateFields(this.$refs.variable_videoCallDetailsHeader, "characterLimit20")
        ) {
          this.highLighter(this.$refs.variable_videoCallDetailsHeader);
        } else if (
          this.localConfig.introConf.adminFeedbackFormHeaderText &&
          (this.validateFields(this.$refs.adminform_headerText, "specialCharsCheck") ||
          this.validateFields(this.$refs.adminform_headerText, "onlySpacesInput"))
        ) {
          this.highLighter(this.$refs.adminform_headerText);
        } else if (
          this.localConfig.introConf.adminFeedbackFormSubHeaderText &&
          (this.validateFields(this.$refs.adminform_subheaderText, "specialCharsCheck") ||
          this.validateFields(this.$refs.adminform_subheaderText, "onlySpacesInput"))
        ) {
          this.highLighter(this.$refs.adminform_subheaderText);
        }

        else if (
          isNaN(parseInt(this.localConfig.introConf.expiry)) ||
          this.validateFields(this.$refs.variable_CallExpiry, "callExpiry")
        ) {
          this.highLighter(this.$refs.variable_CallExpiry);
        } else if ( this.localConfig.introConf && (this.localConfig.introConf.endsAt || this.localConfig.introConf.showScheduling) &&
          !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(
            this.localConfig.introConf.endsAt
          )
        ) {
          this.showAlert(
            "Not a valid end time, please enter in 24hrs, i.e, HH:MM"
          );
        } else if (this.localConfig.introConf && (this.localConfig.introConf.startsAt || this.localConfig.introConf.endsAt || this.localConfig.introConf.showScheduling) && !this.startTimeLessThanEndTime()) {
          this.showAlert(
            "Not a valid end time and start time combination, please make sure that the end time is always greater than start time"
          );
        } else if ( this.localConfig.introConf.startButtonTextRequired && this.validateFields(this.$refs.start_button_text, "startButtonTextCheck")) {
          this.highLighter(this.$refs.start_button_text);
        } else if (
          this.localConfig.introConf.countryWhitelisting &&
          this.validateFields(this.$refs.variable_AllowedCountries, "checkEmpty")
        ) {
          this.highLighter(this.$refs.variable_AllowedCountries);
        } else if (
          this.validateFields(this.$refs.variable_RescheduleConsentText, "alphaNumeric")
        ) {
          this.highLighter(this.$refs.variable_RescheduleConsentText);
        }  else if (
          this.validateFields(this.$refs.variable_RescheduleConsentText, 'specialCharsCheck')
        ) {
          this.highLighter(this.$refs.variable_RescheduleConsentText);
        } else if (
          this.localConfig.introConf.salutationTextRequired &&
          this.validateFields(this.$refs.variable_salutationText, "alphaNumeric")
        ) {
          this.highLighter(this.$refs.variable_salutationText);
        }else if (
          this.validateFields(this.$refs.variable_MinSpeedRequired, "minSpeed")
        ) {
          this.highLighter(this.$refs.variable_MinSpeedRequired);
        } else if (
          this.localConfig.introConf.consentRequired &&
          this.validateFields(this.$refs.variable_ConsentText, "alphaNumeric")
        ) {
          this.highLighter(this.$refs.variable_ConsentText);
        }  else if (
          this.localConfig.introConf.consentRequired &&
          this.validateFields(this.$refs.variable_ConsentText, "specialCharsCheck")
        ) {
          this.highLighter(this.$refs.variable_ConsentText);
        }
          else if (
          this.localConfig.introConf.showETA &&
          this.validateFields(this.$refs.variable_ETA, "numeric")
        ) {
          this.highLighter(this.$refs.variable_ETA);
        } else if (
          this.localConfig.introConf.fallBackImagePath &&
          this.validateFields(this.$refs.variable_fallbackImage, "checkURL")
        ) {
          this.highLighter(this.$refs.variable_fallbackImage);
        } else if (
          this.localConfig.introConf.pollingInterval &&
          this.validateFields(this.$refs.variable_pollingInterval, "numeric")
        ) {
          this.highLighter(this.$refs.variable_pollingInterval);
        } else if (
          this.localConfig.introConf.showScheduling &&
          this.localConfig.introConf.scheduledExpiry &&
          this.validateFields(this.$refs.variable_ScheduledExpiry, "scheduleExp")
        ) {
          this.highLighter(this.$refs.variable_ScheduledExpiry);
        }else if (
          this.localConfig.introConf.showScheduling &&
          this.validateFields(this.$refs.variable_SchedulingDays, "schedulingDays")
        ) {
          console.log("Invalid scheduling window days selected!")
        } else if (
          this.localConfig.introConf.workingDays &&
          this.validateFields(this.$refs.variable_WorkingDays, "workingDays")
        ) {
          console.log("Invalid working days combination selected!")
        } else if(
          this.localConfig.introConf.enableChat &&
          this.validateFields(this.$refs.variable_endUserBgColor, "hexCode"))
        {
          this.highLighter(this.$refs.variable_endUserBgColor);
        } else if(
          this.localConfig.introConf.enableChat &&
          this.validateFields(this.$refs.variable_bankerBgColor, "hexCode"))
        {
          this.highLighter(this.$refs.variable_bankerBgColor);
        } else if(
          this.localConfig.introConf.enableChat &&
          this.validateFields(this.$refs.variable_bankerTextColor, "hexCode"))
        {
          this.highLighter(this.$refs.variable_bankerTextColor);
        } else if(
          this.localConfig.introConf.enableChat &&
          this.validateFields(this.$refs.variable_endUserTextColor, "hexCode"))
        {
          this.highLighter(this.$refs.variable_endUserTextColor);
        } 
        else if(
          this.localConfig.introConf.locationConsistencyVerification && this.localConfig.introConf.thresholdPermanentToIp &&
          this.validateFields(this.$refs.variable_thresholdPermanentToIp, "checkEmptyDistance"))
        {
          this.highLighter(this.$refs.variable_thresholdPermanentToIp);
        }
        else if(
          this.localConfig.introConf.locationConsistencyVerification && this.localConfig.introConf.thresholdPermanentToBrowser &&
          this.validateFields(this.$refs.variable_thresholdPermanentToBrowser, "checkEmptyDistance"))
        {
          this.highLighter(this.$refs.variable_thresholdPermanentToBrowser);
        }
        else if(
          this.localConfig.introConf.locationConsistencyVerification && this.localConfig.introConf.thresholdIpToBrowser &&
          this.validateFields(this.$refs.variable_thresholdIpToBrowser, "checkEmptyDistance"))
        {
          this.highLighter(this.$refs.variable_thresholdIpToBrowser);
        }
        else {
          eventBus.$emit("updateConfig", ["introConf", "languagesOptions"]);
          //Setting updatedSuccessfully as the flag sent from the event
          eventBus.$on("updatedSuccessfully", (flag)=>{
                this.updatedSuccessfully = flag;
          });
        }
      } else {
        if(this.currentFlow != "default"){
          this.config.multiflow[this.currentFlow] = JSON.parse(JSON.stringify(this.localConfig));
        } else{
          this.config = JSON.parse(JSON.stringify(this.localConfig))
        }
        eventBus.$emit("updateConfig", ["introConf"]);
        //Setting updatedSuccessfully as the flag sent from the event
        eventBus.$on("updatedSuccessfully", (flag)=>{
              this.updatedSuccessfully = flag;
              this.$nextTick(()=> {
                this.getFlowData();
              })
        });
      }

    },
  },
  beforeRouteUpdate (to, from, next) {
      this.currentFlow = to.params.id;
      if(this.currentFlow == undefined){
        this.currentFlow = "default";
      }
      this.getFlowData();
      next()
  },
  beforeRouteLeave (to, from, next) {
    //If not updatedSuccessfully then replace it with the localCopy that was created at the start
    if(!this.updatedSuccessfully){
      //Checking if it's a multiflow setting, then update the main config file accordingly
      if(this.currentFlow != "default"){
        //Using deep copy to reset the config file to it's orginal state before leaving the route
        this.config.multiflow[this.currentFlow].introConf = JSON.parse(JSON.stringify(this.localCopy.introConf ));
      } else{
        this.config.introConf = JSON.parse(JSON.stringify(this.localCopy.introConf));
      }
    }
    next()
  },
  updated(){
    if(this.localConfig.introConf.allowedSchedulingDays?.length === 0){
      this.noWeekDaysSelected = true;
    } else {
      this.noWeekDaysSelected = false;
    }
    setTimeout(() => {
      this.getFlowData()
    },0);
  },
  created() {
    this.timeSlots = this.generateTimeSlots();
    if (!this.$store.getters.userData || !sessionStorage.getItem('userId')) {
      if(this.$store.getters.envValues?.client == 'citi') {
        this.$router.push({ path: '/custom/citi/errorpage', query: { errCode: 401 }});
      } else {
        this.$router.push("/");
      }
    } else {
      //  let {userId ,token } = this.$store.getters.userData;
      //  axiosInstance.defaults.headers.common["Authorization"] = token;

       let userData = this.$store.getters.userData;
       if (userData.customerData) {
        this.customerId = userData.customerData.id;
       } else {
        this.customerId = userData.userId
       }
       axiosInstance.defaults.headers.common["Authorization"] = userData.token;


       if (userData.userId) {
        axiosInstance
          .get(end_points.get_customer_data(this.customerId))
          .then((customerData) => {
            if(customerData && customerData.data && customerData.data.config && customerData.data.config.introConf){
              Object.assign(this.config.introConf, customerData.data.config.introConf);
            }else{
              Object.assign(this.config.introConf, this.$store.getters.userData.config.introConf);
            }

            if(this.config && this.config.introConf && this.config.introConf.adminCallTimeoutDuration && typeof(this.config.introConf.adminCallTimeoutDuration) == 'number'){
              this.config.introConf.adminCallTimeoutDuration = this.config.introConf.adminCallTimeoutDuration.toString();
            }

            this.currentFlow = this.$route.params.id;
            if(this.currentFlow == undefined){
              this.currentFlow = "default";
            }
            this.getFlowData();

            this.isVideoConference = this.$store.getters.userData.isVideoConference || false;
            this.isVideoKYC = this.$store.getters.userData.isVideoKYC || false;
            this.isScreenshare = this.$store.getters.userData.isScreenshare || false;
            //this.isRecording = this.$store.getters.userData.isRecording || true;
            this.isRecording = true;
            this.videoCallDetailsHeader = this.config.introConf.videoCallDetailsHeader || 'KYC Data';
            this.showScheduling = this.config.introConf.showScheduling;
            if(typeof this.config.introConf.showScheduling == "undefined") {
              this.config.introConf.showScheduling = true;
              this.showScheduling = true;
            }
            //set default text form admin form labels
            this.localConfig.introConf.adminFeedbackFormHeaderText = this.localConfig.introConf.adminFeedbackFormHeaderText ? this.localConfig.introConf.adminFeedbackFormHeaderText : 'CLASSIFY THE USER APPLICATION BASED ON THE BELOW CRITERIA';
            this.localConfig.introConf.adminFeedbackFormSubHeaderText = this.localConfig.introConf.adminFeedbackFormSubHeaderText ? this.localConfig.introConf.adminFeedbackFormSubHeaderText : 'Kindly select the application status and leave your comments based on the interaction with user';
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  },
  watch: {
    localConfig: async function () {
      this.imageLogo = this.localConfig.introConf.imageLogo ;

      if (!this.imageLogo || this.imageLogo?.length == 0) {
        require("@/assets/signzy.png")
      }
      else if (!this.imageLogo.includes('base64,')) {
        this.imagePersistUrl = this.imageLogo;
        let imageDataResp = await downloadFile(this.imageLogo)
        this.imageLogo = `data:${imageDataResp.headers["content-type"].split(";")[0]};base64, ${imageDataResp.data.file}`
      }

      if (!this.localConfig.introConf.minSpeedRequired) {
        this.localConfig.introConf.minSpeedRequired = 200;
      }
      this.showScheduling = this.localConfig.introConf.showScheduling;
      if(typeof this.localConfig.introConf.showScheduling == "undefined") {
        this.localConfig.introConf.showScheduling = true;
        this.showScheduling = true;
      }
      this.videoCallDetailsHeader = this.localConfig.introConf.videoCallDetailsHeader || 'KYC Data';
      //set default text form admin form labels for old flows
      this.localConfig.introConf.adminFeedbackFormHeaderText = this.localConfig.introConf.adminFeedbackFormHeaderText ? this.localConfig.introConf.adminFeedbackFormHeaderText : 'CLASSIFY THE USER APPLICATION BASED ON THE BELOW CRITERIA';
      this.localConfig.introConf.adminFeedbackFormSubHeaderText = this.localConfig.introConf.adminFeedbackFormSubHeaderText ? this.localConfig.introConf.adminFeedbackFormSubHeaderText : 'Kindly select the application status and leave your comments based on the interaction with user';
    },
    workingHourStarts: function() {
      this.localConfig.introConf.workingHourStarts = convertTo24HourFormat(this.workingHourStarts);
    },
    workingHourEnds: function() {
      this.localConfig.introConf.workingHourEnds = convertTo24HourFormat(this.workingHourEnds);
    }
  },
  props: ["config"],
};
</script>
<style>
.fa-check:before{
  margin-top: 0.12rem;
  margin-left: 0.12rem;
  font-size: small;
}
.fa-exclamation-triangle:before {
  margin-top: 1.22rem;
}
.samplecsv{
  display: flex;
  justify-content: flex-start;
}
.csvtemplate{
  font-size: 12px !important;
  margin-bottom: 0px !important;
  color: rgba(0, 0, 0, 0.6);
}
.filemessage{
  font-size: 12px !important;
  margin-bottom: 0px !important;
  color: rgba(0, 0, 0, 0.6);
  padding: 10px;
}
</style>
